// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gallery-block {
  position: relative;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  inline-size: 100%;
  block-size: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-inline-size: 80%;
  max-block-size: 80%;
  overflow: auto;
  position: relative;
}

.modal-close-button {
  position: absolute;
  top: 1px;
  right: 1px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-image {
  inline-size: 500px;
  block-size: 500px;
  display: block;
  margin-block-end: 10px;
  border: 2px solid #000;
}

.modal-description {
  font-size: 16px;
  color: #333;
}

.modal-tags__container {
  display: flex;
  gap: 5%;
  justify-content: center;
  inline-size: 100%;
}

.modal-tag {
  inline-size: 120px;
  text-align: center;
  border: 1px solid #000;
  border-radius: 20px;
  position: relative;
  align-self: center;
}

body.modal-open {
  overflow: hidden;
}

`, "",{"version":3,"sources":["webpack://./src/components/Gallery/Modal.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,iBAAiB;EACjB,gBAAgB;EAChB,8BAA8B;EAC9B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,kBAAkB;EAClB,oBAAoB;EACpB,mBAAmB;EACnB,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,cAAc;EACd,sBAAsB;EACtB,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,aAAa;EACb,OAAO;EACP,uBAAuB;EACvB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".gallery-block {\n  position: relative;\n}\n\n.modal-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  inline-size: 100%;\n  block-size: 100%;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000;\n}\n\n.modal-content {\n  background: white;\n  padding: 20px;\n  border-radius: 8px;\n  max-inline-size: 80%;\n  max-block-size: 80%;\n  overflow: auto;\n  position: relative;\n}\n\n.modal-close-button {\n  position: absolute;\n  top: 1px;\n  right: 1px;\n  background: none;\n  border: none;\n  font-size: 24px;\n  cursor: pointer;\n}\n\n.modal-image {\n  inline-size: 500px;\n  block-size: 500px;\n  display: block;\n  margin-block-end: 10px;\n  border: 2px solid #000;\n}\n\n.modal-description {\n  font-size: 16px;\n  color: #333;\n}\n\n.modal-tags__container {\n  display: flex;\n  gap: 5%;\n  justify-content: center;\n  inline-size: 100%;\n}\n\n.modal-tag {\n  inline-size: 120px;\n  text-align: center;\n  border: 1px solid #000;\n  border-radius: 20px;\n  position: relative;\n  align-self: center;\n}\n\nbody.modal-open {\n  overflow: hidden;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
