// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.welcome-message__container {
  display: flex;
  justify-content: center;
  align-items: center;
  block-size: calc(100vh - 153px);
}

.welcome-message {
  font-size: 72px;
  line-height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/mainPage/mainPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,+BAA+B;AACjC;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".welcome-message__container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  block-size: calc(100vh - 153px);\n}\n\n.welcome-message {\n  font-size: 72px;\n  line-height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
