// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.authorisation-block {
  display: flex;
  align-items: center;
  justify-content: center;
  block-size: calc(100vh - 153px);
}

.authorisation-container {
  display: flex;
  align-items: center;
  justify-self: center;
  flex-direction: column;
  block-size: 250px;
  inline-size: 450px;
  border: 2px solid #000;
  border-radius: 20px;
  background-color: white;
}

.authorisation-header {
  display: flex;
  align-items: center;
  justify-content: center;
  inline-size: 100%;
  block-size: 45px;
  border-block-end: 2px solid #000;
  font-size: 20px;
}

.authorisation-content {
  margin-block-start: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  block-size: 155px;
}

.authorisation-form {
  block-size: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.input-error::placeholder {
  color: red;
}


`, "",{"version":3,"sources":["webpack://./src/components/Login/Login.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,oBAAoB;EACpB,sBAAsB;EACtB,iBAAiB;EACjB,kBAAkB;EAClB,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;EACjB,gBAAgB;EAChB,gCAAgC;EAChC,eAAe;AACjB;;AAEA;EACE,wBAAwB;EACxB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".authorisation-block {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  block-size: calc(100vh - 153px);\n}\n\n.authorisation-container {\n  display: flex;\n  align-items: center;\n  justify-self: center;\n  flex-direction: column;\n  block-size: 250px;\n  inline-size: 450px;\n  border: 2px solid #000;\n  border-radius: 20px;\n  background-color: white;\n}\n\n.authorisation-header {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  inline-size: 100%;\n  block-size: 45px;\n  border-block-end: 2px solid #000;\n  font-size: 20px;\n}\n\n.authorisation-content {\n  margin-block-start: 25px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  block-size: 155px;\n}\n\n.authorisation-form {\n  block-size: inherit;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 15px;\n}\n\n.input-error::placeholder {\n  color: red;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
