import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import './globalStyles/globals.css';
import Header from './components/Header/Header.js';
import Footer from './components/Footer/Footer.js';
import MainPage from './components/mainPage/mainPage.js';
import Gallery from './components/Gallery/Gallery.js';
import AddPhoto from './components/AddPhoto/AddPhoto.js';
import Users from './components/Users/Users.js';
import User from './components/User/User.js';
import Registration from './components/Registration/Registration.js';
import Login from './components/Login/Login.js';
import reportWebVitals from './reportWebVitals';
import { PhotoProvider } from './components/PhotoContext/PhotoContext.js'; 

function App() {
  const location = useLocation();
  const shouldShowHeaderAndFooter = !['/registration', '/login'].includes(location.pathname);

  return (
    <React.StrictMode>
      {shouldShowHeaderAndFooter && <Header />}
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/add-photo" element={<AddPhoto />} />
        <Route path="/users" element={<Users />} />
        <Route path="/user" element={<User />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/login" element={<Login />} />
      </Routes>
      {shouldShowHeaderAndFooter && <Footer />}
    </React.StrictMode>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <PhotoProvider>
      <App />
    </PhotoProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
