import React, { useState } from 'react';
import Login from '../Login/Login';
import Registration from '../Registration/Registration';

function AuthForm({ onClose, onLogin, onRegister }) {
  const [isLoginForm, setIsLoginForm] = useState(true);

  const toggleForm = () => {
    setIsLoginForm(!isLoginForm);
  };

  return (
    <div>
      {isLoginForm ? (
        <Login onClose={onClose} toggleForm={toggleForm} onLogin={onLogin} />
      ) : (
        <Registration onClose={onClose} toggleForm={toggleForm} onRegister={onRegister} />
      )}
    </div>
  );
}

export default AuthForm;
