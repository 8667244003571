import React, { useState } from 'react';
import './Login.css';

function Login({ onClose, toggleForm, onLogin }) {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const validateForm = () => {
    const newErrors = {};
    
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email) {
      newErrors.email = 'Поле почты не может быть пустым.';
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = 'Введите корректный адрес электронной почты.';
    }

    if (!formData.password) {
      newErrors.password = 'Поле пароля не может быть пустым.';
    } else if (formData.password.length < 8) {
      newErrors.password = 'Пароль должен содержать минимум 8 символов.';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    console.log(JSON.stringify(formData, null, 2));
    try {
      const response = await fetch('https://preview.kvantmaster.ru/rest/v1/auth:authorization', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      console.log('Success: ', result);
      onLogin(result.userName); 
    } catch (error) {
      console.log('Error: ', error);
    }
    onClose();
  };

  return (
    <div className="authorisation-block">
      <div className="authorisation-container">
        <div className="authorisation-header">
          <h2 className="authorisation-text">Авторизация</h2>
        </div>
        <div className="authorisation-content">
          <form className="authorisation-form" name="authorisation" onSubmit={handleSubmit}>
            <input
              type="text"
              name="email"
              className={`form-input input_type_email ${errors.email ? 'input-error' : ''}`}
              placeholder={errors.email || "Почта"}
              id="email-telephone-input"
              minLength="2"
              maxLength="50"
              value={formData.email}
              onChange={handleChange}
            />
            <input
              type="password"
              name="password"
              className={`form-input input_type_password ${errors.password ? 'input-error' : ''}`}
              placeholder={errors.password || "Пароль"}
              id="password-input"
              minLength="8"
              maxLength="50"
              value={formData.password}
              onChange={handleChange}
            />
            <button type="submit" className="button form__button">Сохранить</button>
            <p onClick={toggleForm} style={{ cursor: 'pointer', color: 'blue' }}>Не зарегистрированы?</p>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
