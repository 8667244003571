import React, { useState, useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import logo from '../../public/Logo.png';
import userImage from '../../public/Photo.png';
import './Header.css';
import './Modal.css';
import '../Login/Login.css';
import AuthForm from '../AuthForm/AuthFrom';

function Header() {
  const [isAuthFormOpen, setIsAuthFormOpen] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [activeLink, setActiveLink] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState('');
  const navigate = useNavigate();

  const openAuthForm = () => {
    setIsAuthFormOpen(true);
  };

  const closeAuthForm = () => {
    setIsAuthFormOpen(false);
  };

  const toggleDropdown = () => {
    if (isLoggedIn) {
      setDropdownVisible(!isDropdownVisible);
    }
  };

  const handleOptionClick = (option) => {
    if (option === 'logout') {
      handleLogout();
    }
    setDropdownVisible(false);
  };

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  const handleLogin = (name) => {
    setIsLoggedIn(true);
    setUserName(name);
    closeAuthForm();
  };

  const handleRegister = (name) => {
    setIsLoggedIn(true);
    setUserName(name);
    closeAuthForm();
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUserName('');
  };

  const handleAddPhotoClick = () => {
    if (!isLoggedIn) {
      openAuthForm();
    } else {
      navigate('/add-photo');
    }
  };

  useEffect(() => {
    if (isAuthFormOpen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [isAuthFormOpen]);

  return (
    <header className="template__header">
      <nav className="header__menu">
        <div className="logo-and-name">
          <div className="logo__container">
            <Link
             to="/"
             className={`link-to-welcome-page ${activeLink === '/' ? 'active' : ''}`}
             onClick={() => handleLinkClick('/')}
            >
              <img src={logo} alt="Логотип Миэма" className="miem-logo" />
            </Link>
          </div>
          <div className="project__name">
            <Link
             to="/"
             className={`link-to-welcome-page header__link ${activeLink === '/' ? 'active' : ''}`}
             onClick={() => handleLinkClick('/')}
            >
              Проект 1904
            </Link>
          </div>
        </div>
        <div className="header__options">
          <ul className="header__links-list">
            <li className="header__links-list-item">
              <NavLink
                to="/"
                className={`header__link list__link ${activeLink === '/' ? 'active' : ''}`}
                onClick={() => handleLinkClick('/')}
              >
                Главная
              </NavLink>
            </li>
            <li className="header__links-list-item">
              <NavLink
                to="/gallery"
                className={`header__link list__link ${activeLink === '/gallery' ? 'active' : ''}`}
                onClick={() => handleLinkClick('/gallery')}
              >
                Галерея
              </NavLink>
            </li>
            <li className="header__links-list-item">
              <div
                className={`header__link list__link ${activeLink === '/add-photo' ? 'active' : ''}`}
                onClick={handleAddPhotoClick}
              >
                Добавить фото
              </div>
            </li>
            <li className="header__links-list-item">
              <NavLink
                to="/users"
                className={`header__link list__link ${activeLink === '/users' ? 'active' : ''}`}
                onClick={() => handleLinkClick('/users')}
              >
                Пользователи
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="user">
          <div className="user__name">
            {!isLoggedIn ? (
              <div className='user-link' onClick={openAuthForm}>
                <p>Вход</p>
              </div>
            ) : (
              <div className='user-link'>
                <p>{userName}</p>
              </div>
            )}
          </div>

          {isAuthFormOpen && (
            <div className="login-overlay" onClick={closeAuthForm}>
              <div className="login-content" onClick={(e) => e.stopPropagation()}>
                <AuthForm onClose={closeAuthForm} onLogin={handleLogin} onRegister={handleRegister} />
              </div>
            </div>
          )}
          <div className='user-link'>
            <div className="user-image__container" onClick={toggleDropdown}>
              <img src={userImage} alt="Логотип Миэма" className="user-image" />
            </div>
            {isDropdownVisible && (
              <div className="dropdown">
                <div className="dropdown-option" onClick={() => handleOptionClick('settings')}>
                  Настройки
                </div>
                <div className="dropdown-option" onClick={() => handleOptionClick('logout')}>
                  Выйти
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
