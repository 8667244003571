import './Users.css';
import userImage from '../../public/Photo.png'
function Users() {
  return (
    <div className="users__container">
      <ul className="users__container-list">
        <li className="users__container-list-item">
          <div className="user__container">
            <div className="user-image__container">
              <img src={ userImage } alt="Фото Пользователя" className="user-image" />
            </div>
            <div className="user__name">
              <p>Иванов Иван</p>
            </div>
            <div className="user__info">
              <p>Информация</p>
            </div>
          </div>
        </li>
        <li className="users__container-list-item">
          <div className="user__container">
            <div className="user-image__container">
              <img src={ userImage } alt="Фото Пользователя" className="user-image" />
            </div>
            <div className="user__name">
              <p>Иванов Иван</p>
            </div>
            <div className="user__info">
              <p>Информация</p>
            </div>
          </div>
        </li>
        <li className="users__container-list-item">
          <div className="user__container">
            <div className="user-image__container">
              <img src={ userImage } alt="Фото Пользователя" className="user-image" />
            </div>
            <div className="user__name">
              <p>Иванов Иван</p>
            </div>
            <div className="user__info">
              <p>Информация</p>
            </div>
          </div>
        </li>
        <li className="users__container-list-item">
          <div className="user__container">
            <div className="user-image__container">
              <img src={ userImage } alt="Фото Пользователя" className="user-image" />
            </div>
            <div className="user__name">
              <p>Иванов Иван</p>
            </div>
            <div className="user__info">
              <p>Информация</p>
            </div>
          </div>
        </li>
        <li className="users__container-list-item">
          <div className="user__container">
            <div className="user-image__container">
              <img src={ userImage } alt="Фото Пользователя" className="user-image" />
            </div>
            <div className="user__name">
              <p>Иванов Иван</p>
            </div>
            <div className="user__info">
              <p>Информация</p>
            </div>
          </div>
        </li>
        <li className="users__container-list-item">
          <div className="user__container">
            <div className="user-image__container">
              <img src={ userImage } alt="Фото Пользователя" className="user-image" />
            </div>
            <div className="user__name">
              <p>Иванов Иван</p>
            </div>
            <div className="user__info">
              <p>Информация</p>
            </div>
          </div>
        </li>
        
      </ul>
    </div>
  );
}
export default Users;