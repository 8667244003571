import React, { useState, useEffect } from 'react';
import './Gallery.css';
import './Modal.css';
import { usePhotoContext } from '../PhotoContext/PhotoContext';

const Gallery = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedDescription, setSelectedDescription] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [photos, setPhotos] = useState([]);

  const { addPhotos } = usePhotoContext();

  const openModal = (image, description, tags) => {
    setSelectedImage(image);
    setSelectedDescription(description);
    setSelectedTags(tags);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [isModalOpen]);

  useEffect(() => {
    const fetchPhotos = async () => {
      try {
        const response = await fetch('https://preview.kvantmaster.ru/rest/v1/data:getall');
        if (!response.ok) {
          throw new Error('Failed to fetch photos');
        }
        const data = await response.json();
        setPhotos(data.result.urls?.map((url) => ({ image: url, description: 'Компонент', tags: [] })));
      } catch (error) {
        console.error('Error fetching photos:', error);
      }
    };

    fetchPhotos();
  }, [addPhotos]);

   // useEffect(() => {
  //   const fetchTags = async () => {
  //     try {
  //       const response = await fetch('https://preview.kvantmaster.ru/rest/v1/data:gettag');
  //       if (!response.ok) {
  //         throw new Error('Failed to fetch tags');
  //       }
  //       const data = await response.json();
  //       return data;
  //     } catch (error) {
  //       console.error('Error fetching tags:', error);
  //       throw error;
  //     }
  //   };

  //   fetchTags();
  // }, []);

  return (
    <div className="gallery-block">
      <div className="gallery__container">
        <div className="photos__container">
          <div className="photos">
            {photos.map((photo, index) => (
              <div
                key={index}
                className="photo-card"
                onClick={() => openModal(photo.image, photo.description, photo.tags)}
              >
                <div className="photo__container">
                  <img src={photo.image} alt="Фото Компонента" className="component-image" />
                </div>
                <div className="photo-description">
                  <p>{photo.description}</p>
                  <div className="gallery-tags__container">
                    {photo.tags.map((tag, tagIndex) => (
                      <span key={tagIndex} className="gallery-tag" style={{ backgroundColor: tag.color }}>
                        {tag.name}: {tag.value}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="filter__container">
          <div className="filter">
            <h2 className="filter__header">Фильтр</h2>
            <div className="search-form__checkbox-list">
              <label className="search-form__label">
                <input
                  type="checkbox"
                  name="time"
                  value="morning"
                  className="search-form__checkbox visually-hidden"
                />
                <span className="search-form__pseudo-checkbox"></span>
                <span className="search-form__label-text">Утро</span>
              </label>
              <label className="search-form__label">
                <input
                  type="checkbox"
                  name="time"
                  value="afternoon"
                  className="search-form__checkbox visually-hidden"
                />
                <span className="search-form__pseudo-checkbox"></span>
                <span className="search-form__label-text">День</span>
              </label>
              <label className="search-form__label">
                <input
                  type="checkbox"
                  name="time"
                  value="afternoon"
                  className="search-form__checkbox visually-hidden"
                />
                <span className="search-form__pseudo-checkbox"></span>
                <span className="search-form__label-text">Вечер</span>
              </label>
              <label className="search-form__label">
                <input
                  type="checkbox"
                  name="time"
                  value="night"
                  className="search-form__checkbox visually-hidden"
                />
                <span className="search-form__pseudo-checkbox"></span>
                <span className="search-form__label-text">Ночь</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close-button" onClick={closeModal}>
              &times;
            </button>
            <img src={selectedImage} alt="Выбранное изображение" className="modal-image" />
            <p className="modal-description">{selectedDescription}</p>
            <div className="modal-tags__container">
              {selectedTags.map((tag, index) => (
                <span key={index} className="modal-tag" style={{ backgroundColor: tag.color }}>
                  {tag.name}: {tag.value}
                </span>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
