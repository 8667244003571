// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.users__container-list {
  display: flex;
  flex-direction: column;
  gap: 1px;
  align-items: center;
  justify-content: center;
  block-size: calc(100vh - 153px);
}

.users__container-list-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.user__container {
  block-size: 60px;
  inline-size: 400px;
  border: 2px solid #000;
  display: flex;
  align-items: center;
  justify-content: space-around;
}`, "",{"version":3,"sources":["webpack://./src/components/Users/Users.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,mBAAmB;EACnB,uBAAuB;EACvB,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,6BAA6B;AAC/B","sourcesContent":[".users__container-list {\n  display: flex;\n  flex-direction: column;\n  gap: 1px;\n  align-items: center;\n  justify-content: center;\n  block-size: calc(100vh - 153px);\n}\n\n.users__container-list-item {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.user__container {\n  block-size: 60px;\n  inline-size: 400px;\n  border: 2px solid #000;\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
