import React, { useState } from 'react';
import './AddPhoto.css';
import addPhotoImage from '../../public/default.png';
import { usePhotoContext } from '../PhotoContext/PhotoContext';

function AddPhoto() {
  const [photoTags, setPhotoTags] = useState({});
  const [tagTags, setTagTags] = useState({});
  const [tagName, setTagName] = useState('');
  const [tagValue, setTagValue] = useState('');
  const [newTagName, setNewTagName] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [componentName, setComponentName] = useState('');
  const [componentDescription, setComponentDescription] = useState('');
  const [errors, setErrors] = useState({});
  const [fileUploadOngoing, setFileUploadOngoing] = useState(false);

  const { addPhoto } = usePhotoContext();

  const validateForm = () => {
    const newErrors = {};

    // Проверка названия компонента
    if (componentName.trim() === '') {
      newErrors.componentName = 'Название не может быть пустым.';
    } else if (componentName.length < 2) {
      newErrors.componentName = 'Название должно содержать минимум 2 символа.';
    }

    // Проверка описания компонента
    if (componentDescription.trim() === '') {
      newErrors.componentDescription = 'Описание не может быть пустым.';
    } else if (componentDescription.length < 2 || componentDescription.length > 400) {
      newErrors.componentDescription = 'Описание должно содержать от 2 до 400 символов.';
    }

    // Проверка файла
    if (!selectedFile) {
      newErrors.file = 'Пожалуйста, выберите изображение.';
    } else if (!selectedFile.type.startsWith('image/')) {
      newErrors.file = 'Файл должен быть изображением.';
    } else if (selectedFile.size > 5 * 1024 * 1024) {  // Ограничение по размеру файла 5MB
      newErrors.file = 'Изображение должно быть меньше 5MB.';
    }

    return newErrors;
  };

  const handleTagNameChange = (e) => {
    setTagName(e.target.value);
  };

  const handleTagValueChange = (e) => {
    setTagValue(e.target.value);
  };

  const handleNewTagNameChange = (e) => {
    setNewTagName(e.target.value);
  };

  const handleAddPhotoTag = (e) => {
    e.preventDefault();
    if (tagName.trim() !== '' && tagValue.trim() !== '') {
      setPhotoTags(prevTags => ({
        ...prevTags,
        [tagName.trim()]: tagValue.trim()
      }));
      setTagName('');
      setTagValue('');
      setErrors(prevErrors => ({ ...prevErrors, tag: '' }));
    } else {
      setErrors(prevErrors => ({ ...prevErrors, tag: 'Имя и значение тега не могут быть пустыми.' }));
    }
  };

  const handleAddTagTag = (e) => {
    e.preventDefault();
    if (newTagName.trim() !== '') {
      setTagTags(prevTags => ({
        ...prevTags,
        [newTagName.trim()]: ''
      }));
      setNewTagName('');
      setErrors(prevErrors => ({ ...prevErrors, tag: '' }));
    } else {
      setErrors(prevErrors => ({ ...prevErrors, tag: 'Имя тега не может быть пустым.' }));
    }
  };

  const handleRemovePhotoTag = (tagName) => {
    setPhotoTags(prevTags => {
      const newTags = { ...prevTags };
      delete newTags[tagName];
      return newTags;
    });
  };

  const handleRemoveTagTag = (tagName) => {
    setTagTags(prevTags => {
      const newTags = { ...prevTags };
      delete newTags[tagName];
      return newTags;
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type.startsWith('image/')) {
        setSelectedFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewImage(reader.result);
        };
        reader.readAsDataURL(file);
        setErrors(prevErrors => ({ ...prevErrors, file: '' }));
      } else {
        setErrors(prevErrors => ({ ...prevErrors, file: 'Пожалуйста, выберите изображение.' }));
      }
    }
    return file;
  };

  const uploadImageAndInfo = async (file, title, description, tags) => {
    const formData = new FormData();
    formData.append('photo', file);
    formData.append('info', JSON.stringify({
      'title': title,
      'description': description,
      'tags': tags
    }));

    const options = {
      method: 'POST',
      body: formData
    };

    const response = await fetch('https://preview.kvantmaster.ru/rest/v1/data:upload', options);

    if (!response.ok) {
      throw new Error('Failed to upload image and info');
    }

    return response.json();
  };

  const uploadTag = async (tagName) => {
    const tagData = {
      name: tagName
    };

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(tagData)
    };

    const response = await fetch('https://preview.kvantmaster.ru/rest/v1/data:tag', options);

    if (!response.ok) {
      throw new Error('Failed to upload tag');
    }

    return response.json();
  };

  const handlePhotoFormSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length === 0) {
      try {
        setFileUploadOngoing(true);
        await uploadImageAndInfo(selectedFile, componentName, componentDescription, photoTags);

        addPhoto({
          image: previewImage || addPhotoImage,
          description: componentDescription,
          tags: photoTags,
        });

        setComponentName('');
        setComponentDescription('');
        setPhotoTags({});
        setSelectedFile(null);
        setPreviewImage(null);
        setErrors({});
      } catch (error) {
        console.error('Error uploading image:', error);
        setErrors(prevErrors => ({ ...prevErrors, api: 'Ошибка при загрузке данных на сервер.' }));
      } finally {
        setFileUploadOngoing(false);
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const handleTagFormSubmit = async (e) => {
    e.preventDefault();
    if (newTagName.trim() !== '') {
      try {
        await uploadTag(newTagName.trim());
        setTagTags(prevTags => ({
          ...prevTags,
          [newTagName.trim()]: ''
        }));
        setNewTagName('');
        setErrors(prevErrors => ({ ...prevErrors, tag: '' }));
      } catch (error) {
        console.error('Error uploading tag:', error);
        setErrors(prevErrors => ({ ...prevErrors, api: 'Ошибка при загрузке тега на сервер.' }));
      }
    } else {
      setErrors(prevErrors => ({ ...prevErrors, tag: 'Имя тега не может быть пустым.' }));
    }
  };

  return (
    <div className="forms__container">
      <div className="add-photo-content">
        <form onSubmit={handlePhotoFormSubmit} className="add-photo__form">
          <div className="add-photo-image__container">
            {previewImage ? (
              <img src={previewImage} alt="Выбранное фото" className="add-photo-image" />
            ) : (
              <img src={addPhotoImage} alt="Фото Компонента" className="add-photo-image" />
            )}
          </div>
          <div className="image-info">
            <input
              type="text"
              className="form-input input_type_component_name"
              name="component_name"
              placeholder="Название"
              minLength="2"
              value={componentName}
              onChange={(e) => setComponentName(e.target.value)}
            />
            {errors.componentName && <div className="error">{errors.componentName}</div>}
            <textarea
              className="form-input input_type_info"
              rows="10"
              cols="40"
              maxLength="400"
              minLength="2"
              placeholder="Описание"
              id="info-input"
              name="info"
              value={componentDescription}
              onChange={(e) => setComponentDescription(e.target.value)}
            ></textarea>
            {errors.componentDescription && <div className="error">{errors.componentDescription}</div>}
            <div className="tags-input__container">
              <input
                type="text"
                className="form-input input_type_tag_name"
                name="tag_name"
                placeholder="Введите имя тега"
                minLength="2"
                value={tagName}
                onChange={handleTagNameChange}
              />
              <input
                type="text"
                className="form-input input_type_tag_value"
                name="tag_value"
                placeholder="Введите значение"
                minLength="2"
                value={tagValue}
                onChange={handleTagValueChange}
              />
              <button type="button" className="button tag__button" onClick={handleAddPhotoTag}>+</button>
            </div>
            {errors.tag && <div className="error">{errors.tag}</div>}
            <input
              type="file"
              name='file'
              accept="image/*"
              className="form-input input_type_file"
              onChange={handleFileChange}
            />
            {errors.file && <div className="error">{errors.file}</div>}
            <button type="submit" className="button form__button">Сохранить</button>
          </div>
          <div className="tags__container">
            {Object.keys(photoTags).map((tagName) => (
              <span key={tagName} className="tag">
                {tagName}: {photoTags[tagName]}
                <button type="button" className="tag__remove-button" onClick={() => handleRemovePhotoTag(tagName)}>
                  x
                </button>
              </span>
            ))}
          </div>
        </form>
      </div>
      <div className="add-tag__container">
        <form onSubmit={handleTagFormSubmit} className="add-tag__form">
          <div className="tag-info">
            <input
              type="text"
              className="form-input second-input_type_tag_name"
              name="tag_name"
              placeholder="Введите имя тега"
              minLength="2"
              value={newTagName}
              onChange={handleNewTagNameChange}
            />
            <button type="submit" className="button form__button">Сохранить</button>
          </div>
          {errors.tag && <div className="error">{errors.tag}</div>}
        </form>
        <div className="tags__container">
          {Object.keys(tagTags).map((tagName) => (
            <span key={tagName} className="tag">
              {tagName}: {tagTags[tagName]}
              <button type="button" className="tag__remove-button" onClick={() => handleRemoveTagTag(tagName)}>
                x
              </button>
            </span>
          ))}
        </div>
      </div>
      {fileUploadOngoing && (
        <div>
          <h1>File upload ongoing...</h1>
        </div>
      )}
    </div>
  );
}

export default AddPhoto;
