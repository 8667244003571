import React, { useState } from 'react';
import './Registration.css';

function Registration({ onClose, toggleForm, onRegister }) {
  const [formData, setFormData] = useState({
    Name: '',
    Role: '',
    Email: '',
    Password: '',
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.Name.trim()) {
      newErrors.Name = 'Фамилия и Имя не могут быть пустыми.';
    } else if (!/^[a-zA-Zа-яА-ЯёЁ\s-]+$/.test(formData.Name)) {
      newErrors.Name = 'Разрешены только буквы, дефис и пробелы.';
    }

    if (!formData.Role.trim()) {
      newErrors.Role = 'Роль не может быть пустой.';
    } else if (!/^[a-zA-Zа-яА-ЯёЁ\s-]+$/.test(formData.Role)) {
      newErrors.Role = 'Разрешены только буквы, дефис и пробелы.';
    }

    if (!formData.Email.trim()) {
      newErrors.Email = 'Почта не может быть пустой.';
    } else if (!/\S+@\S+\.\S+/.test(formData.Email)) {
      newErrors.Email = 'Введите корректный адрес электронной почты.';
    }

    if (!formData.Password.trim()) {
      newErrors.Password = 'Пароль не может быть пустым.';
    } else if (formData.Password.length < 8) {
      newErrors.Password = 'Пароль должен содержать минимум 8 символов.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    console.log(JSON.stringify(formData, null, 2));
    try {
      const response = await fetch('https://preview.kvantmaster.ru/rest/v1/auth:registration', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      console.log('Success: ', result);
      onRegister(formData.Name);
    } catch (error) {
      console.log('Error: ', error);
    }
    onClose();
  };

  return (
    <div className="registration-block">
      <div className="registration-container">
        <div className="registration-header">
          <h2 className="registration-text">Регистрация</h2>
        </div>
        <div className="registration-content">
          <form className="registration-form" name="registration" onSubmit={handleSubmit}>
            <input
              type="text"
              name="Name"
              className={`form-input input_type_name-surname ${errors.Name ? 'input-error' : ''}`}
              placeholder={errors.Name || "Фамилия и Имя"}
              id="name-surname-input"
              minLength="2"
              maxLength="50"
              value={formData.Name}
              onChange={handleChange}
            />
            <input
              type="text"
              name="Role"
              className={`form-input input_type_role ${errors.Role ? 'input-error' : ''}`}
              placeholder={errors.Role || "Роль"}
              id="role"
              minLength="2"
              maxLength="50"
              value={formData.Role}
              onChange={handleChange}
            />
            <input
              type="email"
              name="Email"
              className={`form-input input_type_email ${errors.Email ? 'input-error' : ''}`}
              placeholder={errors.Email || "Почта"}
              id="email-input"
              minLength="2"
              maxLength="50"
              value={formData.Email}
              onChange={handleChange}
            />
            <input
              type="password"
              name="Password"
              className={`form-input input_type_password ${errors.Password ? 'input-error' : ''}`}
              placeholder={errors.Password || "Пароль"}
              id="password-input"
              minLength="8"
              maxLength="50"
              value={formData.Password}
              onChange={handleChange}
            />
            <button type="submit" className="button form__button">Сохранить</button>
            <p onClick={toggleForm} style={{ cursor: 'pointer', color: 'blue' }}>Уже зарегистрированы?</p>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Registration;
